<template>
  <div>
    <el-dialog
        title="添加线上订单"
        width="55%"
        top="10vh"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-steps :active="active" finish-status="success" simple>
        <el-step title="填写订单信息" icon="el-icon-edit"></el-step>
        <el-step title="填写收款信息" icon="el-icon-edit"></el-step>
        <el-step title="确认订单信息" icon="el-icon-check"></el-step>
        <el-step title="完成" icon="el-icon-finished"></el-step>
      </el-steps>
      <div style="margin-top: 30px">
        <el-form label-width="100px" :model="step1" v-show="active===0" :rules="step1Rules" ref="step1">
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="订单日期" prop="createDate">
                <el-date-picker type="date" placeholder="预约日期"
                                v-model="step1.createDate"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="精确婚期">
                <el-date-picker type="date" placeholder="精确婚期"
                                v-model="step1.weddingDay"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="订单号">
                <el-input v-model="step1.orderNo" placeholder="请填写订单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="订单项目">
                <el-input value="线上订单" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="订单店铺" prop="shopId">
                <el-select v-model="step1.shopId" placeholder="预约店铺" clearable style="width: 100%">
                  <el-option
                      v-for="item in shopArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="订单备注">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="step1.orderRemark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="step1Next">下一步</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="100px" :model="step2" v-show="active===1" :rules="step2Rules" ref="step2">
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="收款项目" prop="proceedsName">
                <el-select placeholder="请选择收款项目" v-model="step2.proceedsName" style="width: 100%;">
                  <el-option
                      v-for="item in proceedsNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="收款方式" prop="payment">
                  <el-cascader
                      :props="{checkStrictly: true,
                            expandTrigger: 'hover',
                            label: 'name'}"
                      multiple="false"
                      :show-all-levels="false"
                      style="width: 100%;"
                      :options="paymentArray"
                      v-model="step2.payment"
                      ref="payment"
                      @change="cascaderClsoe"></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="订单总价">
                <el-switch
                    v-model="check"
                    active-color="#13ce66">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="check">
            <el-col :span="10" :offset="7">
              <el-form-item label="订单总价">
                <el-input v-model="step2.orderPrice"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="收款金额" prop="spareMoney">
                <el-input v-model.number="step2.spareMoney"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="check">
            <el-col :span="10" :offset="7">
              <el-form-item label="余额">
                <el-input v-model="step2.orderSpare"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" :offset="7">
              <el-form-item label="收款人" prop="payee">
                <el-select placeholder="请选择收款人" v-model="step2.payee" style="width: 100%;" ref="service">
                  <el-option
                      v-for="item in payeeArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="text-align: center">
            <el-button @click="previous">上一步</el-button>
            <el-button type="primary" @click="step2Next">下一步</el-button>
          </el-form-item>
        </el-form>
        <div v-show="active === 2">
          <el-row class="orderConfirm">
            <el-col :offset="10">
              客户名：&nbsp &nbsp{{cus.name}}
            </el-col>
            <el-col :offset="10">
              手机号：&nbsp &nbsp{{cus.phone}}
            </el-col>
            <el-col :offset="10">
              订单编号：&nbsp &nbsp{{step1.orderNo}}
            </el-col>
            <el-col :offset="10">
              订单项目：&nbsp &nbsp线上订单
            </el-col>
            <el-col :offset="10">
              订单总价：&nbsp &nbsp{{step2.orderPrice}}
            </el-col>
            <el-col :offset="10">
              收款金额：&nbsp &nbsp{{step2.spareMoney}}
            </el-col>
          </el-row>
          <div style="text-align: center;margin-top: 20px">
            <el-button @click="previous">上一步</el-button>
            <el-button type="primary" @click="onlineOrderSubmit">确认入款</el-button>
          </div>
        </div>
        <div v-show="active === 3">
          <el-result icon="success" title="线上订单录入成功">
            <template slot="extra">
              <el-button type="primary" size="medium" @click="toOrderList">查看订单</el-button>
            </template>
          </el-result>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "online-order-add",
  created() {
    this.pageInit();
  },
  props: {
    cus:{
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    onlineOrderAddState:{
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {
      state: this.onlineOrderAddState,
      active: 0,
      proceedsNameArray: [],
      paymentArray: [],
      payeeArray: [],
      check: false,
      flag: true,
      shopArray: [],
      step1:{
        // 订单日期
        createDate: new Date(),
        // 精确婚期
        weddingDay: "",
        // 订单号
        orderNo: "",
        // 订单项目
        orderName: "",
        //订单店铺
        shopId: localStorage.getItem("shops").split(",").map(Number)[0],
        // 订单备注
        orderRemark: "",
      },
      step2:{
        // 收款项目
        proceedsName: "",
        // 收款方式
        payment: "",
        // 订单总价
        orderPrice: "",
        // 收款金额
        spareMoney: "",
        // 余额
        orderSpare: "",
        // 收款人
        payee: "",
      },
      step1Rules: {
        createDate: [{required: true, message: '请选择订单日期', trigger: 'change'}],
        shopId: [{required: true, message: '请选择订单店铺', trigger: 'change'}]
      },
      step2Rules: {
        proceedsName: [{required: true, message: '请选择收款项目', trigger: 'change'}],
        payment: [{required: true, message: '请选择收款方式', trigger: 'change'}],
        spareMoney: [{required: true, message: '请输入收款金额', trigger: 'blur'}],
        payee: [{required: true, message: '请选择收款人', trigger: 'change'}]
      },
    }
  },
  watch: {
    'step2.orderPrice'(valOne) {
      this.step2.orderSpare = valOne - this.step2.spareMoney;
    },
    'step2.spareMoney'(valOne) {
      this.step2.orderSpare = this.step2.orderPrice - valOne;
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryOnlineOrderProceedsName().then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        let shops = localStorage.getItem("shops").split(",").map(Number)
        this.shopArray = JSON.parse(response.data.data).filter(s => shops.includes(s.value));
      })
    },
    close() {
      this.$emit("close");
    },
    step1Next() {
      this.$refs['step1'].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    step2Next() {
      console.log(this.$refs['step2'])
      this.$refs['step2'].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    previous() {
      this.active--
    },
    next() {
      this.active++
    },
    //级联选择关闭
    cascaderClsoe() {
      this.$refs.payment.dropDownVisible = false;
    },
    //线上订单提交
    onlineOrderSubmit() {
      if (!this.check) {
        this.step2.orderPrice = null
        this.step2.orderSpare = null
      }
      let val = this.step2.payment
      this.step2.payment = val.length ===1?val[0]:val.length === 2?val[1]:val
      this.$axios({
        method: "POST",
        url: "/order/saveOnlineOrder",
        params: {
          ...this.step1,
          ...this.step2,
          cusId: this.cus.id,
          orderCity: this.cus.appointCity,
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.next()
        } else {
          this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error',
            duration: 1000,
          });
        }
      })
    },
    toOrderList() {
      this.$router.push("/work/order/order-list")
    },
  },
}
</script>

<style scoped>
.orderConfirm div{
  margin-top: 15px;
}
</style>